<template>
  <div class="article-box form-apply" style="min-height: 500px" v-loading="pageLoading">
    <div class="pag-title clearfix" style="margin-top: 0">
      <div class="page-title-name pull-left">会费缴纳</div>
      <div class="page-title-button pull-right" v-if="this.Prioritystates === false" @click="fees(2)">
        缴费
      </div>
      <div class="page-title-button pull-right" v-if="this.Prioritystates === true" @click="fees(1)">
        缴费
      </div>
    </div>
    <div class="top" style="margin-top: 20px">
      <el-descriptions :column="3" border>
        <el-descriptions-item label="交通运输协会会员等级：" label-class-name="my-label">
          <span v-if="this.plenaryLevel === 1">会员</span>
          <span v-if="this.plenaryLevel === 2">理事</span>
          <span v-if="this.plenaryLevel === 3">常务理事</span>
          <span v-if="this.plenaryLevel === 4">副会长</span>
        </el-descriptions-item>
        <el-descriptions-item :label="orgName + '会员等级：'" label-class-name="my-label">
          <span v-if="this.orgLevel === 1">会员</span>
          <span v-if="this.orgLevel === 2">理事</span>
          <span v-if="this.orgLevel === 3">常务理事</span>
          <span v-if="this.orgLevel === 4">副会长</span>
        </el-descriptions-item>
        <el-descriptions-item label="证书有效期：" label-class-name="my-label">
          {{ this.certificateExpireTime ? this.certificateExpireTime + '-12-31' : "-" }}
        </el-descriptions-item>
      </el-descriptions>
    </div>

    <!-- <div class="table" v-if="this.articleList.length != ''"> -->
      <div class="table">
        <div class="radio-title">缴费记录</div>
        <el-table border :data="articleList" highlight-current-row header-row-class-name="table-header-class"
          :header-cell-style="{ 'background-color': '#F7F8FA' }" style="width: 100%; ">
          <el-table-column prop="payYear" label="年份" >
          </el-table-column>
          <el-table-column prop="overPayLv" label="历年会员等级" >
            <template slot-scope="scope">
              <span v-if="scope.row.overPayLv === 1">会员</span>
              <span v-if="scope.row.overPayLv === 2">理事</span>
              <span v-if="scope.row.overPayLv === 3">常务理事</span>
              <span v-if="scope.row.overPayLv === 4">副秘书长</span>
            </template>
          </el-table-column>
          <el-table-column prop="overPayMoney" label="历年会费应缴金额" >
          </el-table-column>
          <el-table-column prop="actualPayLv" label="实际缴费等级" >
            <template slot-scope="scope">
              <span v-if="scope.row.actualPayLv === 1">会员</span>
              <span v-if="scope.row.actualPayLv === 2">理事</span>
              <span v-if="scope.row.actualPayLv === 3">常务理事</span>
              <span v-if="scope.row.actualPayLv === 4">副秘书长</span>
            </template>
          </el-table-column>

          <el-table-column prop="actualPayMoney" label="实际缴费金额" >
          </el-table-column>
          <el-table-column prop="publishTime" label="缴纳情况" >
          </el-table-column>
        </el-table>
        <el-pagination style="text-align: right; padding-top: 20px" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80]"
          layout="total, sizes, prev, pager, next, jumper" :page-size="pageSize" :current-page="pageNo"
          :page-count="totalPage" :total="totalCount">
        </el-pagination>
      </div>
    <!-- </div> -->
    <!-- <div v-else class="elseBut"></div> -->
    <!-- 会费缴纳 -->
    <el-dialog title="会费缴纳" :visible="addDialogshow" width="900px" :close-on-click-modal="false" :show-close="true"
      @close="priority">
      <el-form ref="form1" :model="po1" class="demo-form-inline" label-width="100px">
        <div class="clearfix">
          <el-form-item class="form-item-50" label="缴费年数" prop="feeYears">
            <el-input placeholder="请填写缴费年数" @input="getYears" v-model="po1.feeYears"></el-input>
          </el-form-item>
          <el-form-item class="form-item-50" label="应缴金额" label-width="150px" prop="paidFee">
            <el-input placeholder="请填写应缴金额" v-model="po1.paidFee" :disabled="true"></el-input>
          </el-form-item>
        </div>
        <el-form-item label-width="20px">
          <div>
            <span class="text-btn danger">* 注： </span>

            汇款时请备注单位名称，注明“会费”，并保存流水号和支付截图凭证
          </div>
        </el-form-item>
      </el-form>

      <div class="tabCon">
        <!-- <div class="tab-list">
          <div v-for="(item, index) in tab" :key="index" class="tab">
            <div
              class="type-name"
              :class="{ changeStyle: changeSelectStyle == index }"
              @click="tabsss(item.id, index)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>

        <div class="con" v-if="this.type === 1">
        
        </div>
        <div class="con" v-if="this.type === 2">
       
        </div>
        <div class="con" v-if="this.type === 3">
         
        </div>
        <div class="con" v-if="this.type === 4">
         
        </div> -->

        <el-tabs type="border-card">
          <el-tab-pane label="银行账户付款">
            <el-form ref="form1" label-position="left" :model="po1" class="demo-form-inline" label-width="100px">

              <el-form-item label="户 名" prop="unitName">
                <el-input placeholder="请填写户名" :disabled="true" size="medium" v-model="po1.name"></el-input>
              </el-form-item>
              <el-form-item label="开户银行" prop="bank">
                <el-input placeholder="请填写开户银行" v-model="po1.bank" :disabled="true" size="medium"></el-input>
              </el-form-item>

              <el-form-item label="账 号" prop="account">
                <el-input placeholder="请填写账号" v-model="po1.account" :disabled="true" size="medium"></el-input>
              </el-form-item>
              <el-form-item label="开户银行代码" prop="code">
                <el-input placeholder="请填写开户银行代码" v-model="po1.code" :disabled="true" size="medium"></el-input>
              </el-form-item>
            </el-form>
            <el-button type="primary" @click="add(1)" class="buttomss">已付款</el-button>
          </el-tab-pane>
          <template v-if="false">
            <el-tab-pane label="微信付款">
              <br>
              <h2>请扫当前二维码进行年费缴纳</h2>
              <br>
              <div class="imgdiv">
                <img src="../../assets/images/consigne.png" alt="" class="img" />
              </div>
              <br>
              <el-button type="primary" @click="add(2)" class="buttomss">已付款</el-button>
            </el-tab-pane>
            <el-tab-pane label="支付宝付款"> <br>
              <h2>请扫当前二维码进行年费缴纳</h2>
              <br>
              <div class="imgdiv">
                <img src="../../assets/images/consigne.png" alt="" class="img" />
              </div> <br>
              <el-button type="primary" @click="add(3)" class="buttomss">已付款</el-button>
            </el-tab-pane>
            <el-tab-pane label="银联支付">
              <div class="h4">
                <h4>暂缓开通</h4>
              </div>
              <!-- <el-button type="primary" @click="add(3)" class="buttomss">已付款</el-button> -->
            </el-tab-pane>
          </template>
          
        </el-tabs>
      </div>
    </el-dialog>
    <!-- 已缴费 -->
    <el-dialog title="支付凭证" :visible="addDialog" :close-on-click-modal="false" :show-close="true" @close="clearSubmit">
      <el-form ref="form1" :model="po2" class="demo-form-inline" label-width="150px">
        <div class="clearfix">
          <el-form-item class="form-item-50" label="付款时间" prop="payTime">
            <el-date-picker style="width: 100%;" v-model="po2.payTime" type="date" placeholder="选择日期" :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item class="form-item-50" label="缴费年数"  prop="feeYears">
            <el-input placeholder="请填写缴费年数" v-model.number="po2.feeYears"></el-input>
          </el-form-item>

          <el-form-item class="form-item-50" label="应缴金额" prop="paidFee">
            <el-input placeholder="应缴金额" v-model.number="po1.paidFee" disabled></el-input>
          </el-form-item>
          <el-form-item class="form-item-50" label="实缴金额" prop="paidFeeSum">
            <el-input placeholder="实缴金额" v-model.number="po2.paidFeeSum"></el-input>
          </el-form-item>
          <el-form-item class="form-item-50" label="支付流水号" prop="paySn">
            <el-input placeholder="请填写支付流水号" v-model="po2.paySn"></el-input>
          </el-form-item>

          <el-form-item class="form-item-50" label="支付截图" prop="attFiles">
            <UploadImage :config="uploadImageConfig" @UploadImageFun="UploadImageFun"></UploadImage>
          </el-form-item>
        </div>
        <p style="padding-left: 30px; margin-bottom: 40px; font-weight: 700;">发票信息</p>
        <div class="clearfix">
          <el-form-item class="form-item-50" label="单位名称" prop="companyName">
            <el-input placeholder="请填写单位名称" v-model="po2.companyName"></el-input>
          </el-form-item>
          <el-form-item class="form-item-50" label="税号" prop="taxCode">
            <el-input placeholder="请填写税号" v-model="po2.taxCode"></el-input>
          </el-form-item>

          <el-form-item class="form-item-50" label="联系人" prop="contectName">
            <el-input placeholder="请填写联系人" v-model="po2.contectName"></el-input>
          </el-form-item>
          <el-form-item class="form-item-50" label="收票邮箱" prop="email">
            <el-input placeholder="请填写收票邮箱" v-model="po2.email"></el-input>
          </el-form-item>
        </div>
        <div class="clearfix">
          <el-form-item class="form-item-50" label="联系电话" prop="contectTel">
            <el-input placeholder="请填写联系电话" v-model="po2.contectTel"></el-input>
          </el-form-item>
          <el-form-item class="form-item-50" label="邮寄地址" prop="mailingAddress">
            <el-input placeholder="请填写邮寄地址" v-model="po2.mailingAddress"></el-input>
          </el-form-item>
          <el-form-item class="form-item-50" label="邮编" prop="zipCode">
            <el-input placeholder="请填写邮编" v-model="po2.zipCode"></el-input>
          </el-form-item>
        </div>
        <div class="clearfix">
          <el-form-item class="form-item-50" label="备注" prop="invoiceRemark">
            <el-input style="width: 100%" type="textarea" :rows="3" v-model="po2.invoiceRemark" placeholder="请输入备注">
            </el-input>
          </el-form-item>
        </div>
      </el-form>

      <span slot="footer">
        <el-button plain @click="clearSubmit">关闭</el-button>
        <el-button type="primary" size="medium" @click.native.prevent="addDialogSubmit()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- <el-dialog
      title="提示"
      :visible="show"
      width="600px"
      :close-on-click-modal="false"
      :show-close="true"
      @close="resumeClear1"
    >
     您暂未通过入会申请，请先完成入会申请后，方可查看
       <span slot="footer">
        <el-button
          type="primary"
          size="medium"
          @click.native.prevent="AmendSubmits"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
  </div>
</template>
<script>
import FileEcho from "@c/upload/FileEcho.vue";
import UploadImage from "@c/upload/UploadImage.vue";

import {
  Priority, // 缴费
  Prioritystate, //状态
  StandardGet, //会费等级
  InfoGet,
  Listpay,
} from "@/http/api/InitiationApi";
export default {
  name: "membership",
  props: {},
  components: {
    FileEcho,
    UploadImage,
  },
  data () {
    return {
      orgName: '',
      pageSize: 20,
      pageNo: 1,
      totalPage: 1,
      totalCount: 1,

      certificateExpireTime: "",
      orgLevel: "",
      plenaryLevel: "",
      Prioritystates: true,
      uploadImageConfig: {
        limit: 1,
        callback: "",
      },
      type: 1,
      pageLoading: false,
      articleList: [],
      po1: {
        feeYears: "",
        name: "中国交通运输协会",
        bank: "交通银行北京市分行营业部",
        account: "110060149012015021805",
        code: "3011-0000-0023",
        paidFee: "",
      },
      po2: {
        attFiles: "", // 支付截图路径集合,逗号分隔 ,
        companyName: "", // 单位名称 ,
        contectName: "", // 联系人 ,
        contectTel: "", // 联系电话 ,
        email: "", // 收票邮箱 ,
        expectedFeeSum: "", // 应缴金额,
        feeYears: "", // 缴费年数 ,
        invoiceRemark: "", // 发票备注 ,
        mailingAddress: "", // 邮寄地址 ,
        memberLevel: "", // 当时缴费分支机构会员等级 从根据缴费年数获取应缴费用和会员等级中获取 ,
        paidFeeSum: 0, //        实缴金额,
        paySn: "", // 支付流水号 ,
        payTime: "", // 付款时间 ,
        payWay: "", // 付款方式 1.银行账户付款 2.微信付款 3.支付宝付款 4.银联支付 ,
        taxCode: "", // 税号 ,
        zipCode: "", // 邮编
      },
      addDialogshow: false,
      addDialog: false,
      attFileList: [],
      pickerOptions: {},
      tab: [
        {
          id: 1,
          name: "银行账户付款",
        },
        {
          id: 2,
          name: "微信付款",
        },
        {
          id: 3,
          name: "支付宝付款",
        },
        {
          id: 4,
          name: "银联支付",
        },
      ],
      changeSelectStyle: "",
    };
  },

  methods: {
    getList () {
      Listpay({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        this.articleList = res.result.records;
      });
    },
    getYears (e) {
      StandardGet({
        id: this.po1.feeYears,
      }).then((res) => {
        this.po1.paidFee = res.result.paidFee;
        this.po2.memberLevel = res.result.memberLevel;
      });
    },
    UploadImageFun (val) {
      this.po2.attFiles = val;
    },
    add (num) {
      if (num === 1) {
        this.addDialog = true;
        this.po2.payWay = 1;
      } else if (num === 2) {
        this.addDialog = true;
        this.po2.payWay = 2;
      } else if (num === 3) {
        this.addDialog = true;
        this.po2.payWay = 3;
      } else if (num === 4) {
        this.addDialog = true;
        this.po2.payWay = 4;
      }
    },
    addDialogSubmit () {
      Priority({
        attFiles: this.po2.attFiles, // 支付截图路径集合,逗号分隔 ,
        companyName: this.po2.companyName, // 单位名称 ,
        contectName: this.po2.contectName, // 联系人 ,
        contectTel: this.po2.contectTel, // 联系电话 ,
        email: this.po2.email, // 收票邮箱 ,
        expectedFeeSum: this.po1.paidFee, // 应缴金额,
        feeYears: this.po2.feeYears, // 缴费年数 ,
        invoiceRemark: this.po2.invoiceRemark, // 发票备注 ,
        mailingAddress: this.po2.mailingAddress, // 邮寄地址 ,
        memberLevel: this.po2.memberLevel, // 当时缴费分支机构会员等级 从根据缴费年数获取应缴费用和会员等级中获取 ,
        paidFeeSum: this.po2.paidFeeSum, //        实缴金额,
        paySn: this.po2.paySn, // 支付流水号 ,
        payTime: this.po2.payTime, // 付款时间 ,
        payWay: this.po2.payWay, // 付款方式 1.银行账户付款 2.微信付款 3.支付宝付款 4.银联支付 ,
        taxCode: this.po2.taxCode, // 税号 ,
        zipCode: this.po2.zipCode, // 邮编
      }).then((res) => {
        if (res.code === "000") {
          this.$message({
            message: "缴费成功！！！",
            type: "success",
          });
          this.addDialog = false;
          this.addDialogshow = false;
          this.getState();
        }
      });
    },
    fees (num) {
      const user = window.localStorage.getItem("userPermissions");
      let Permissions = user?.toString();
      // console.log(Permissions);
      if (Permissions === "1") {
        if (num === 1) {
          this.addDialogshow = true;
        } else {
          this.$message({
            message: "已经缴纳过费用，无需缴纳！！！",
            type: "error",
          });
        }
      } else {
        alert('请先入会')
        this.$router.push({ path: '/apply/unit'});
      }
      
    },
    tabsss (type, index) {
      if (type === 1) {
        this.type = 1;

        this.changeSelectStyle = index;
      } else if (type === 2) {
        this.type = 2;
        this.changeSelectStyle = index;
      } else if (type === 3) {
        this.type = 3;

        this.changeSelectStyle = index;
      } else if (type === 4) {
        this.type = 4;

        this.changeSelectStyle = index;
      }
    },

    handleCurrentChange (val) {
      this.pageNo = val;
      this.getList();
    },
    handleSizeChange (val) {
      this.pageNo = 1;
      this.pageSize = val;
      this.getList();
    },
    clearSubmit () {
      this.addDialog = false;
    },
    priority () {
      this.addDialogshow = false;
    },
    getState () {
      Prioritystate().then((res) => {
        if (res.code === "000") {
          this.Prioritystates = res.result;
        }
      });
    },
  },

  created () {
    this.getList();
    this.getState();
    InfoGet().then((res) => {
      if (res.code === "000") {
        this.certificateExpireTime = res.result.certificateExpireTime;
        this.orgLevel = res.result.orgLevel;
        this.plenaryLevel = res.result.plenaryLevel;
        this.orgName = res.result.orgName;
      } else {
      }
    });
  },
};
</script>
<style   lang="less" scoped>
.tab {
  width: 500px;
  justify-content: space-around;
  display: flex;
}

.img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.imgdiv {
  width: 300px;
  height: 300px;
  /* margin: 0px 100px 0px 100px; */
  margin: auto;
}

.tab-list {
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 50px;
  margin-top: 10px;
  align-items: center;
  margin-bottom: 10px;
  background-color: rgba(252, 249, 249, 0.993);
}

.changeStyle {
  border-width: 0px;
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: inherit;
  background-color: rgba(242, 242, 242, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(215, 215, 215, 1);
  border-radius: 0px;
  box-shadow: none;
}

.con {
  margin-top: 10px;
  width: auto;
  height: auto;

  p {
    width: 200px;
    height: 200px;
  }
}

.elseBut {
  width: 100%;
  height: 300px;
  background: url("../../assets/images/nodata.png") no-repeat center center;
}

.button {
  margin-left: 100px;
}

/deep/.my-label {
  width: 150px;
}

.page-title-button {
  background-image: url("~@/assets/images/money.png");
}

.radio-title {
  font-size: 16px;
  padding: 30px 0px 20px 14px;
  position: relative;
}

.radio-title::after {
  content: "";
  width: 4px;
  height: 17px;
  position: absolute;
  left: 0;
  top: 35px;
  background-color: #1e63b0;
  border-radius: 2px;
}

.tabCon {
  width: 700px;
  height: 500px;
  margin-left: 90px;
}

.buttomss {
  margin-left: 45%;
}

h2 {
  margin-left: 27%;
}

.h4 {
  width: 300px;
  height: 300px;

  h4 {
    padding-top: 150px;
    // margin-top: 150px;
    padding-left: 80%;
  }
}
</style>